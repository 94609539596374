@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* list-style: none;
  list-style-type: none;
  text-decoration: none; */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

:root {
    --primary-color: #222;
    --heading-color: #2B3758;
    --sub-heading-color: #212a57;
    --bannertext-color: #fff;
    --whitetext-color: #fff;
    --anchor-color: #222;
    --anchor-hover-color: #FFB41F;
    --bluebg-color: #2B3758;
    --footerbg-color: #132043;
    --yellowbg-color: #FFB41F;
    --lightbluebg-color: #F0F6F8;
    --searchbg-color: #525c76;
    --border-color: #8585854d;
    --customthumb-color: #9ED6E3;
    --peach-color: #FAEFD9;
}

input:focus-visible {
    outline-offset: 0px;
}

input:focus {
    outline: 0px;
    border: 1px dashed #ddd;
}

select:focus {
    outline: none;
}


textarea:focus-visible {
    outline-offset: 0px;
}

textarea:focus {
    outline: 0px;
    border: 1px dashed #ddd;
}

html {
    scroll-padding-top: 10rem;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    --primary-color: #222;
}


a {
    color: var(--anchor-color);
}

p {
    font-size: 13px;
    color: var(--primary-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', serif;
    color: var(--heading-color);
}

b,
strong {
    font-weight: 600 !important;
    color: var(--sub-heading-color);
}

.custom-tooltip {
    --bs-tooltip-bg: #4a6dcd;
    --bs-tooltip-color: #fff;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--border-color);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--bluebg-color);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--yellowbg-color);
}

/* start custom scroll bar */
.customscroll::-webkit-scrollbar-track {
    border-radius: 8px;
    box-shadow: none;
}

.customscroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.customscroll::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--customthumb-color);
}

/* end custom scroll bar */



/*Responsive table css */
.restbl table tr th {
    position: sticky;
    top: -1px;
}

.restbl table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

.restbl {
    margin-bottom: 20px;
}


.restbl table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 5px;
}

.restbl table tr:nth-child(even) td {
    background: #f0f6f8;
}

.restbl table tr td {
    background: #fff;
    padding: 7px;
    border: 1px solid #d6e0e3;
    text-align: left;
    word-wrap: break-word;
    text-transform: capitalize;
    vertical-align: baseline;
}

.restbl table tr th {
    background: #ddecf1;
    padding: 7px;
    border: 1px solid #d6e0e3;
    text-transform: uppercase;
    text-align: left;
    word-wrap: break-word;
}

@media screen and (max-width: 600px) {

    .restbl table {
        border: 0;
    }

    .restbl table caption {
        font-size: 1.3em;
    }

    .restbl table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .restbl table tr {
        border-bottom: 3px solid #d6e0e3;
        display: block;
        margin-bottom: 10px;
    }

    .restbl table td {
        border-bottom: 1px solid #d6e0e3;
        display: block;
        font-size: 12px;
        /* text-align: right !important; */
    }

    .restbl table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        margin-right: 8px;
    }

    .restbl table td:last-child {
        border-bottom: 1px solid #d6e0e3;
    }
}

/*Responsive table css */






text.highcharts-credits {
    display: none;
}

.clist p {
    margin-bottom: 3px;
    color: var(--heading-color);
    font-size: 15px;
    font-weight: 600;
}

.clistbtm {
    margin-bottom: 10px;
}

.ailments.clistbtm a:hover {
    color: initial;
}

.calucl .hflex-in {
    flex: 1;
}

.age-incal {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 0px;
    justify-content: left;
    align-items: center;
}

.age-incal23 {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: baseline;
}

.calcul-res-in p {
    word-wrap: break-word;
    line-height: 22px;
    margin-bottom: 0px;
}

.age-inner input {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--border-color) !important;
    background-color: transparent;
    padding: 10px 10px 10px 0px;
    max-width: 160px;
    width: 100%;
}

.dosetbl {
    padding-top: 10px;
}

.age-inner p {
    font-weight: 700;
    color: var(--sub-heading-color);
}

.ageecal {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}

.calcul-res-in {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
}

p.formulaused {
    margin-bottom: 7px;
}

input.agetxt {
    padding-right: 0 !important;
    width: 100px !important;
}

select.ageselect {
    padding-right: 0 !important;
    width: 92px !important;
}

.agee {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0px;
}

a.backk:hover {
    color: #f8c750;
}

a.backk {
    font-size: 15px;
    font-weight: 500;
    color: var(--heading-color);
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}

.agew1 {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.agew {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.clearbtn {
    color: var(--yellowbg-color);
    cursor: pointer;
    background: #fff;
    display: inline-block;
    padding: 5px 25px;
    border-radius: 30px;
    text-decoration: none;
    text-align: center;
    border: 1px solid var(--yellowbg-color);
}

.agew select {
    border: none;
    border-bottom: 1px solid var(--border-color) !important;
    background-color: transparent;
    padding: 10px 0;
    width: 100%;
    color: #757d9f;
}

.commonbtn {
    background: var(--footerbg-color);
    display: inline-block;
    padding: 5px 15px;
    border-radius: 30px;
    text-decoration: none;
    color: var(--whitetext-color);
    text-align: center;
    cursor: pointer;
}

.age-incal23_.age-incal {
    justify-content: center;
}

.calcul-tbl table tr:nth-child(even) td {
    background: #f0f6f8;
}

.disc p {
    margin-bottom: 5px;
}

.disc {
    text-align: center;
    margin-top: 50px;
}

.calcul-tbl table tr td {
    background: #fff;
    padding: 7px;
    border: 1px solid #d6e0e3;
}

.calcul-tbl table {
    width: 100%;
    margin-bottom: 20px;
}

.calcul-tbl table tr th {
    background: #ddecf1;
    padding: 7px;
    border: 1px solid #d6e0e3;
}

.calcul-h {
    font-size: 19px;
    color: var(--yellowbg-color);
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.calcula-fields label {
    text-transform: uppercase;
    font-weight: 600;
}

.nomrdicine {
    color: #afacac;
}

.medi-dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.medi-br {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.medi-in-scroll {
    height: 167px;
    overflow: auto;
}

.medi-in {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.medi i {
    color: #939a9d;
    font-size: 10px;
}

.medi {
    background: var(--whitetext-color);
    padding: 3px 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 2px #c0e2ed;
    border: 1px solid #c0e2ed;
    color: var(--heading-color);
    /* cursor: pointer; */
    font-size: 11px;
    font-weight: 500;
}

.instraf-in1 {
    margin-top: 20px;
}

.deleteall {
    border-radius: 5px;
    background: #9d1d22 !important;
    color: #fff !important;
    box-shadow: 0px 0px 2px #9d1d22 !important;
    border: 1px solid #9d1d22 !important;
}

.deleteall i {
    color: #fff !important;
    font-size: 11px !important;
}

span.harm {
    background: #FF0100 !important;
    float: right;
    color: #fff !important;
    border-radius: 5px;
    padding: 4px 10px;
    font-size: 12px !important;
}

span.caution {
    float: right;
    background: #FFB41F;
    color: #fff !important;
    border-radius: 5px;
    padding: 4px 10px;
    font-size: 12px !important;
}

.dieseasebanner {
    background: url(../images/DiseaseBanner.png);
}

.medicinebanner {
    background: url(../images/MedicineBanner.png);
}

.foodbanner {
    background: url(../images/FoodnHerbs.png);
}

.nutrientbanner {
    background: url(../images/Nutrients.png);
}

.medicalbanner {
    background: url(../images/MedicalBanner1.png);
}

.Invstigationbanner {
    background: url(../images/Invstigationbanner.png);
}

.searchmenu input[type="search"]::-webkit-input-placeholder {
    color: var(--whitetext-color);
}

.searchmenu input[type="search"]:-moz-placeholder {
    color: var(--whitetext-color);
}




.yellowbg {
    background: var(--yellowbg-color);
    padding: 20px;
}

.ft-blue {
    background: var(--footerbg-color);
    padding: 20px;
}

.ft-yellow-left h3 {
    color: var(--heading-color);
    font-size: 19px;
    font-family: 'Poppins', sans-serif;
}

.ft-yellow-left p {
    font-size: 14px;
    color: var(--whitetext-color);
    margin-bottom: 3px;
}

.spacebetween {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.ft-yellow-rt {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}

.signups input {
    background: var(--whitetext-color);
    border: none;
    padding: 9px 20px;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 500;
}

.signups a img {
    height: 20px;
}

.signups a {
    background: var(--footerbg-color);
    ;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 30px;
    text-decoration: none;
    color: var(--whitetext-color);
    font-size: 15px;
}

ul.footermenu li a {
    color: var(--whitetext-color);
    text-decoration: none;
}

ul.footermenu li {
    display: inline;
    color: var(--whitetext-color);
}

ul.footermenu {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
}

ul.footermenu li a:hover {
    opacity: 0.6;
}

ul.socialicons {
    padding: 0;
    margin: 0px 0 10px;
    list-style: none;
    display: flex;
    gap: 18px;
    justify-content: right;
}

ul.socialicons li {
    display: inline;
}

ul.socialicons li i {
    color: var(--whitetext-color);
    font-size: 25px;
}

ul.socialicons li i:hover {
    opacity: .5;
}

.ft-blue-ct {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.footerborder {
    border-top: 1px solid var(--bluebg-color);
    padding-top: 15px;
}

.ft-top {
    color: var(--whitetext-color);
}

.get img {
    height: 40px;
}

.apps {
    display: flex;
    gap: 15px;
    align-items: center;
}

.header-cnt {
    background: var(--bluebg-color);
    height: 63px;
}

.bannerct img {
    width: 100%;
    height: auto;
}

.banner {
    position: relative;
    padding: 40px 0 35px;
    background-size: cover;
    background-position: center;
    color: var(--bannertext-color);
}

.bannertxt h3 {
    color: var(--bannertext-color);
    font-size: 25px;
    font-family: 'Playfair Display', serif;
}

.bannertxt p {
    font-size: 13px;
    color: var(--bannertext-color);
}

.search i {
    position: absolute;
    left: 10px;
    top: 14px;
    color: var(--border-color);
    font-size: 14px;
}

.search {
    position: relative;
    min-width: 250px;
    width: 100%;
}

.search input {
    border-radius: 40px;
    border: 1px solid var(--border-color);
    padding: 8px 10px 8px 30px;
    width: 100%;
}

.filterr {
    display: flex;
    padding: 40px 0;
    gap: 50px;
}

.alphabet {
    width: 33px;
    height: 33px;
    line-height: 33px;
    border: 1px solid var(--yellowbg-color);
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    color: var(--heading-color);
}

.alphabet:hover {
    background: var(--yellowbg-color);
    color: var(--whitetext-color);
}

.alphabet.active {
    background: var(--yellowbg-color);
    color: var(--whitetext-color);
}

.alphabet-in {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

h4.head {
    color: var(--heading-color);
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
}

.rts .hflex {
    margin-bottom: 15px;
}

.sct {
    padding: 0px 0 30px 0;
}

.Specialty {
    color: var(--primary-color);
    text-transform: capitalize;
}

.Specialty-in {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--lightbluebg-color);
    ;
    overflow: auto;
}

.ailments a {
    text-decoration: none;
    margin-bottom: 10px;
    display: inline-block;
    text-transform: capitalize;
}

.ailments a:hover {
    color: var(--anchor-hover-color);
}

.ailments-in {
    /* display: flex;
    flex-direction: column;
    gap: 15px; */
    overflow: auto;
}

.hflex {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.rtc img {
    height: 15px;
}

.rtc {
    cursor: pointer;
}

.logo {
    height: 50px;
}

.menu a.dropdown-item {
    font-size: 13px;
}

.menu a.nav-link {
    color: var(--whitetext-color);
    padding: 18px 0;
    border-bottom: 4px solid var(--bluebg-color);
    font-size: 15px;
}

.menu .navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: var(--whitetext-color);
    font-weight: 500;
    border-bottom: 4px solid #FFB41F;
}

.menu .navbar-nav .nav-link.show {
    border-bottom: none1;
}

.menu .dropdown-item:focus,
.menu .dropdown-item:hover {
    background: var(--bluebg-color);
    ;
    color: var(--whitetext-color);
}

.searchmenu {
    position: relative;
}

.searchmenu i {
    position: absolute;
    top: 16px;
    left: 12px;
    color: var(--whitetext-color);
}

.searchmenu input {
    background: var(--searchbg-color);
    border: 1px solid var(--searchbg-color);
    font-size: 14px;
    padding: 10px 10px 10px 30px;
    border-radius: 30px;
    color: var(--whitetext-color);
}

.navbar-toggler {
    background-color: var(--whitetext-color);
}

.dropdown.dd button {
    border-radius: 30px;
    background: var(--searchbg-color);
    border: 1px solid var(--searchbg-color);
}

.dropdown.dd img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px dashed #949bb5;
    margin-left: 5px;
    padding: 1px;
    background: #545b74;
}

.dropdown.dd ul.dropdown-menu.show i {
    font-size: 11px;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background: #ddd;
    border-radius: 50%;
}

.menu .dropdown-item:focus,
.menu .dropdown-item:hover i {
    background: var(--whitetext-color) !important;
    color: #2b3758;
}

.searchmenu .form-control:focus {
    background-color: #f1efee33;
    color: var(--whitetext-color);
}

.Specialty-in ul.nav.nav-tabs {
    border-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.Specialty-in ul.nav.nav-tabs li {
    width: 100%;
    position: relative;
    padding: 5px 0;
}

.Specialty-in button.nav-link {
    width: 100%;
    text-align: left;
    padding: 0;
    background: transparent;
    border: none;
}

.Specialty-in button.nav-link:hover {
    border: none;
}

.tabb {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.Specialty-in .nav-tabs .nav-item.show .nav-link,
.Specialty-in .nav-tabs .nav-link.active {
    border-radius: 0px;
    background: transparent;
    font-weight: 700;
    padding-left: 18px;
}

.mheader-inc {
    background: #FAFAFA;
    border-radius: 15px;
    padding: 20px;
    margin: 20px auto 20px;
    max-width: 600px;
    text-align: left;
    box-shadow: 0px 4px 19px 0px rgb(216 213 206 / 30%);
}

.changepbtn button {
    width: auto !important;
    padding: 5px 50px !important;
}

.changepbtn {
    display: flex;
    justify-content: center;
}

.marg {
    margin-bottom: 100px !important;
}

img.chban {
    height: 320px;
    margin: auto;
}

.ch-in {
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: center;
}

.ch {
    flex: 1;
}

.Specialty-in .nav-tabs .nav-link.active:before {
    content: "";
    position: absolute;
    top: 14px;
    left: 0;
    height: 15px;
    width: 12px;
    background: url(../images/check.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.blog-in {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    padding-bottom: 35px;
}

.blog a {
    text-decoration: none;
}

.yelowbtn a:hover {
    background: var(--footerbg-color);
}

.bluebtn a:hover {
    background: var(--heading-color);
}

.yelowbtn a {
    background: var(--yellowbg-color);
    padding: 8px 10px;
    border-radius: 30px;
    display: inline-block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    margin-bottom: 20px;
    font-weight: 500;
    color: var(--whitetext-color);
}

.yelowbtn a img {
    height: 18px;
    margin-right: 2px;
}

/* .as {
    border-bottom: 1px solid #eff6f8;
    padding: 6px 0;
} */

.bluebtn a {
    background: var(--footerbg-color);
    padding: 8px 10px;
    border-radius: 30px;
    display: inline-block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    margin-bottom: 20px;
    color: var(--whitetext-color);
    font-family: 'Playfair Display', serif;
    font-size: 20px;
}

.bluebtn a img {
    height: 23px;
    margin-right: 2px;
}

.graybtn:hover {
    background: #d9d9d942;
}

.graybtn {
    background: var(--border-color);
    padding: 8px 20px;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    border: 1px solid #ddd;
    align-items: center;
}

.graybtn1:last-child {
    margin-bottom: 0px;
}

.graybtn1 {
    margin-bottom: 20px;
}

.rightslider {
    margin-bottom: 20px;
}

.jpdf {
    margin-top: 0px;
}

.jtext {
    line-height: 17px;
}

.graybtn1 a {
    text-decoration: none;
}

.graybtn1 img {
    min-width: 23px;
}

.bnraedcrunn {
    padding: 20px 0 10px;
}

.jt {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.mainheadn {
    font-size: 25px;
    /* font-family: 'Playfair Display', serif; */
    color: var(--yellowbg-color);
    font-weight: 600;
    text-transform: capitalize;
}

.rticons-in {
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--searchbg-color);
}

.detailspagetxt span {
    color: var(--heading-color);
}

.icns img {
    height: 18px;
    margin-right: 3px;
}

.dis-in {
    display: flex;
    column-gap: 15px;
    margin-bottom: 10px;
}

.mail-in img {
    width: 50px;
    height: 50px;
    background: #E6EDF7;
    border-radius: 50%;
    padding: 7px;
}

.mail-in {
    color: #202529;
}

.mail-in a {
    text-decoration: none;
}

.mail-in p {
    margin-bottom: 0px;
}

.mail-in strong {
    font-size: 15px;
}

.dis-in a {
    text-decoration: none;
}

.quiz-in img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.quiz-in {
    display: flex;
    column-gap: 10px;
    margin-bottom: 20px;
}

.quiz {
    flex: 1;
}

.blog img {
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    margin-bottom: 15px;
}

.vid img {
    object-fit: cover;
    width: 100%;
}

.story h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
}

.innerbanner {
    background: #F0F6F8;
    padding: 30px 0 30px;
}

.innerbanner h3 {
    color: #132043;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 2px;
}

.innerbanner p {
    color: #132043;
    font-size: 14px;
    font-weight: 500;
}

.contacttxt {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.topp {
    padding-top: 30px;
    padding-bottom: 30px;
}

.contactcnt {
    flex: 1;
}

.conne {
    background: #FAFAFA;
    padding: 20px;
    border-radius: 20px;
}

img.contactpic {
    height: 350px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 20px;
}

.contactcnt-in button {
    width: 100%;
    margin-top: 40px;
    background: #FFB41F;
    border: none;
    color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 16px;
}

.profile12 .prom strong {
    font-size: 20px;
}

.profile12 .prom i {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    background: #cfe1e7;
    text-align: center;
    font-size: 9px;
}

.profile12 {
    display: flex;
    flex-direction: column;
    gap: 6px;
    color: #2e3556;
}

.profilecont img {
    max-width: 110px;
    max-height: 110px;
    min-width: 110px;
    min-height: 110px;
    border-radius: 50%;
    border: 1px dashed #a9a7a7;
    padding: 3px;
}

.avtar {
    max-width: 110px;
    max-height: 110px;
    min-width: 110px;
    min-height: 110px;
    line-height: 110px;
    border-radius: 50%;
    border: 1px dashed #a9a7a7;
    padding: 3px;
    text-align: center;
    font-size: 71px;
}

.profilecnt {
    margin-bottom: 20px;
}

.profilepicvb {
    display: flex;
    gap: 30px;
    align-items: center;
}

.contactcnt-in label {
    width: 100%;
    margin-bottom: 5px;
    font-weight: 500;
}

.contactform {
    margin-bottom: 10px;
}

.contactcnt-in input {
    border: navajowhite;
    background: #fff padding-box;
    padding: 10px 10px;
    width: 100%;
    margin-bottom: 5px;
    border-radius: 10px;
    box-shadow: 0px 0px 23px #ddd;
}

.contactcnt-in textarea {
    border: navajowhite;
    background: #fff padding-box;
    padding: 10px;
    width: 100%;
    margin-bottom: 5px;
    box-shadow: 0px 0px 23px #ddd;
    border-radius: 10px;
    height: 110px;
}

.innerbanner .bnraedcrunn {
    color: #83837e;
}

.innerbanner .bnraedcrunn a:hover {
    text-decoration: none;
}

.innerbanner .bnraedcrunn ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 10px;
}

.disclaimertxt strong {
    font-size: 17px;
    margin-bottom: 4px;
    display: inline-block;
}

.disclaimertxt p span {
    font-weight: 600;
    font-style: italic;
    font-size: 13px;
}

.disclaimertxt p {
    border-bottom: 1px dashed #ddd;
    padding-bottom: 12px;
}

.disclaimertxt p a {
    color: #d5930f;
    padding: 0 5px;
}

.formcontact {
    max-width: 500px;
    margin: auto;
}

.faqs-in h2 {
    font-family: 'Poppins';
}

.faqs-in button {
    padding: 10px 0;
    font-size: 14px;
    font-weight: 500;
}

.faqs-in .accordion-item {
    border-bottom: 1px solid #ddd !important;
    border: none;
    border-radius: 0px;
}

.faqs-in .accordion-body p {
    margin-bottom: 0px;
    color: #5E707B;
}

.faqs-in .accordion-button:not(.collapsed) {
    background: transparent;
}

.faqs-in .accordion-body {
    padding: 10px 5px;
}

.faqs-in button:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
}

.faqs-in .accordion-button::after {
    background-image: url(../images/Plus.svg);
}

.faqs-in .accordion-button:not(.collapsed)::after {
    background-image: url(../images/Minus.svg);
}

p.get {
    color: #FFB41F;
    margin-bottom: 5px;
    font-weight: 700;
}

.maill:last-child {
    margin-bottom: 0px;
}

p.lets {
    color: #132043;
    font-size: 35px;
    font-weight: 500;
    line-height: 40px;
}

.maill {
    display: flex;
    gap: 10px;
    align-items: center;
    background: #fff;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 23px #ddd;
}

.aboutcnt-in p {
    text-align: justify;
    margin-bottom: 0;
    line-height: 22px;
}

.aboutcnt-in h3 {
    font-weight: 700;
    font-size: 25px;
}

.aboutcnt {
    display: flex;
    gap: 80px;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 30px;
}

img.aboutpic {
    height: 280px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.aboutcnt-in {
    flex: 1;
}

.story a {
    text-decoration: none;
}

.tbb {
    padding: 25px 0 15px;
}

.blogbg {
    background-color: var(--lightbluebg-color);
}

.featurebg {
    background-color: var(--whitetext-color);
    border-top: 1px dashed #ddd;
}

.blog span {
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: var(--searchbg-color);
    font-style: italic;
}

.blog h4 {
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.blog h3 {
    font-size: 13px;
    margin-bottom: 12px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.nodat {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.nodat img {
    width: 80%;
}

.bann img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border: 1px solid var(--border-color);
    border-radius: 10px;
}

.detailspagetxt h2 {
    font-size: 22px;
    font-weight: 700;
}

.detailspagetxt h3 {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;
}

.lsting-tabl {
    margin-bottom: 12px;
    overflow: auto;
}

table.tbl-d.medi {
    margin-bottom: 0px;
    border: 1px solid #D6DCEB;
    width: 100%;
}

table.tbl-d tr th {
    color: #274782;
    font-weight: 500;

    font-size: 13px !important;
    padding: 5px 5px;
    margin-bottom: 0;
    background: var(--lightbluebg-color);
    border: 1px solid #c6e3eb;
}

table.tbl-d.border tr th {
    border: 1px solid #ccc;
}

table.tbl-d tr:last-child td {
    border-bottom: 0;
}

table.tbl-d tbody tr:hover {
    background-color: #F0F6F8;
}

p.subhed {
    margin-bottom: 0px;
}

p.Epidemiologysub {
    color: var(--heading-color);
    margin-bottom: 0px;
}

table.tbl-d tr td {
    padding: 5px 5px;
    border: 1px solid #ccc;
    font-size: 13px;
}

table.tbl-d.border tr td {
    border: 1px solid #ccc;
}

.detailspagetxt p {
    text-align: justify;
    line-height: 22px;
    text-transform: capitalize;
}

.detailspagetxt ul li {
    line-height: 23px;
    text-transform: capitalize;
}

.detailspagetxt ul {
    list-style-type: circle;
}

.detailspagetxt {
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 15px;
    padding-bottom: 10px;
    /* background: var(--lightbluebg-color); */
}

.detailspagetxt h4 {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.listde.siderffectsp ul {
    list-style: none;
    padding: 0;
    max-height: 50vh;
    margin-top: 0;
}

span.Watchable img {
    margin-right: 5px;
}

span.Watchable {
    color: #6e4300 !important;
}

.listde.siderffectsp ul li {
    margin-bottom: 5px;
    padding: 5px 10px;
    margin-left: 0px;
    color: #0f2957;
    background: var(--lightbluebg-color);
    border: 1px solid #c6e3eb;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

span.darkred {
    background: #fc0405;
}

span.Watchable {
    background: #ffc114;
}

.sidee span {
    color: #fff;
    padding: 0px 10px;
    display: inline-block;
    border-radius: 5px;
    font-size: 13px;
    margin-left: 0px;
}

.sidee {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.agevalid {
    color: red;
    font-style: italic;
}

.blog img {
    overflow: hidden;
    height: 200px;
    object-fit: cover;
}

.blog:hover img {
    /* transform: scale(1.1); */
    transition: all .5s ease-in;
    filter: grayscale(1);
}

.icns a {
    text-decoration: none;
    color: var(--searchbg-color);
}

.icns a:hover {
    color: var(--anchor-hover-color);
}

.story a:hover {
    color: var(--anchor-hover-color);
}

.icns a:hover img {
    filter: sepia(1);
}

.journals h2 {
    font-size: 20px;
    font-weight: 600;
}

.related {
    background-color: var(--lightbluebg-color);
    padding: 10px 15px;
    border-radius: 10px;
    margin: 0px 0 20px;
}

.related li {
    line-height: 23px;
}

.related ul {
    list-style-type: circle;
    margin-bottom: 10px;
}

.related h4 {
    font-size: 17px;
    font-weight: 700;
}

.related .spacebetween {
    margin-bottom: 10px;
}

.eventss {
    font-size: 17px;
    font-weight: 700;
    font-family: 'Playfair Display', serif;
}

.viewall a {
    text-decoration: none;
}

.viewall a:hover {
    color: var(--anchor-hover-color);
}

.relatedbrdr {
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 15px;
    padding-bottom: 10px;
}

.dis1 p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 12px;
}

.dis1 span {
    color: var(--searchbg-color);
}

.dis1 img {
    border: 1px solid var(--border-color);
    border-radius: 10px;
}

a.ref {
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    word-break: break-all;
}

.menuicon img {
    height: 18px;
    margin-bottom: 3px;
    width: 100%;
}

.list-group-item.active {
    z-index: 2;
    background-color: var(--lightbluebg-color);
    border: none;
    border-bottom: 2px solid var(--bluebg-color) !important;
    border-right: 1px solid var(--border-color);
    font-weight: 700;
    color: var(--bluebg-color);
    border-radius: 0px;
    border-top: 1px solid var(--border-color);
}

.submenupage {
    border: 1px solid var(--border-color);
    border-radius: 3px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.submenupage:last-child a {
    border-right: 0px !important;
}

.submenupage a {
    font-weight: 500;
    color: var(--searchbg-color);
    border-right: 1px solid var(--border-color) !important;
    border-radius: 0px;
    width: 100%;
    /* border-bottom: 1px solid var(--border-color); */
    text-decoration: none;
    display: inline-block;
    padding: 8px 2px;
    text-align: center;
    flex: 1 1 auto;
    font-size: 12px;
    line-height: 14px;
    border-bottom: 2px solid var(--whitetext-color);
}

.submenupage a:hover {
    background-color: var(--lightbluebg-color);
    border-bottom: 2px solid var(--bluebg-color);
}

.submenupage a.active img {
    /* filter: sepia(500%) hue-rotate(0deg) saturate(500%); */
    filter: brightness(0.5);
}

.dis-in a:hover {
    text-decoration: underline;
}

.quiss {
    position: relative;
}

.quiss-block i {
    width: 100%;
    font-size: 25px;
}

.quiss a {
    color: var(--whitetext-color);
    text-decoration: none;
}

.quiss:hover .quiss-block {
    display: block !important;
    position: absolute;
    top: 50%;
    transform: translate(-15%, -50%);
    left: 15%;
    text-shadow: 0px 2px 10px #1e1616;
    font-size: 17px;
    text-align: center;
    line-height: 15px;
}

.quiztext {
    border-radius: 10px;
    text-align: center;
    background: var(--footerbg-color);
}

.quiztext a {
    color: #fff;
    text-decoration: none;
    font-size: 17px;
    line-height: 18px;
    padding-top: 29px;
    display: inline-block;
}

.rt-blog {
    background: #f0f6f824;
    padding: 12px;
    border-radius: 4px;
    border: 1px dashed #c9d4d7;
    margin-bottom: 20px;
}

/* .nutrienttab-left, .nutrienttab-rt {
    display: flex;
    gap: 10px;
    align-items: center;
} */
.pagination-cnt ul {
    gap: 5px;
}

.pagination-cnt li a {
    color: #2b3758;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    font-size: 13px;
    text-align: center;
}

.pagination-cnt .active>.page-link,
.pagination-cnt .page-link.active {
    background: #ffb41f;
    border-color: #ffb41f;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    font-size: 13px;
    text-align: center;
}

.nutrienttab-rt {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between;
}

.nutrienttab {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 25px;
}

.nutrient-in a {
    border: 1px solid var(--searchbg-color);
    color: var(--searchbg-color);
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
}

.nutrient-in a:hover {
    background: var(--bluebg-color);
    color: var(--whitetext-color);
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
}

.nutrient-in.active a {
    background: var(--bluebg-color);
    color: var(--whitetext-color);
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
}

.foodcn {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 5px;
    background-color: var(--lightbluebg-color);
    padding: 5px 10px;
    font-size: 15px;
    color: var(--bluebg-color);
    font-weight: 600;
}

ul.foodlistt {
    list-style: none;
    padding: 0 0px;
    margin: 0;
    height: 488px;
    overflow-y: auto;
}

ul.foodlistt li:last-child a {
    border-bottom: 0px;
}

.pagination-cnt .page-item:first-child .page-link,
.pagination-cnt .page-item:last-child .page-link {
    border-radius: 50% !important;
    font-size: 18px;
    line-height: 28px;
}

section.footer-cnt {
    margin-top: 20px;
}

ul.foodlistt li a span {
    float: right;
    text-align: right;
    color: var(--bluebg-color);
    font-weight: 600;
}

ul.foodlistt li a {
    text-decoration: none;
    padding: 6px 10px;
    border-bottom: 1px solid var(--border-color);
    display: block;
}

.pagination-cnt {
    margin-top: 20px;
}

.nutrient-r .search i {
    top: 10px;
    font-size: 12px;
}

.nutrient-r .search input {
    padding: 5px 10px 5px 30px;
}

.foodser select {
    border-radius: 40px;
    border: 1px solid var(--border-color);
    padding: 4px 10px;
    color: var(--searchbg-color);
    width: 100%;
}

.foodlist-cn {
    margin-bottom: 20px;
}

.eq-h {
    padding: 0px;
    border: 1px solid #b2b0d361;
    border-radius: 5px;
}

.bann1 img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
}

img.picslide {
    height: 350px;
    object-fit: contain;
    border: 1px solid #ddd;
}

.bannere {
    /* border: 1px solid #ddd; */
    margin-bottom: 15px;
    border-radius: 0px;
}

.overlaybannerbg {
    height: 100%;
    width: 100%;
    position: absolute;
    background: #9f9e9e2e;
    top: 0;
    left: 0;
}

.refercsh {
    padding-right: 7px;
}

.bannere h5 span {
    background: #2e3556;
    text-shadow: 0px 2px 5px #384067;
    font-size: 18px;
    text-transform: capitalize;
    padding: 5px 20px;
    border-radius: 10px;
    font-weight: 700;
    color: #fff;
}

.cv ul {
    height: 42vh;
    overflow: auto;
}

.df {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

span.refercsh-in {
    margin-top: 7px;
    display: inline-block;
}

.bann-gall {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    /* margin-bottom: 15px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(478px, 1fr));
     grid-auto-rows: 250px 150px; 
    grid-auto-flow: dense; */
}

.what {
    border: 1px solid #F2F3F4;
    border-radius: 10px;
    padding: 30px 20px;
    display: flex;
    justify-content: space-around;
    gap: 10px;
}

.what-in {
    /* display: flex;
    flex-wrap: wrap;
    gap: 30px; */
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    /* grid-auto-rows: 250px 150px;
    grid-auto-flow: dense; */
}

.what-inner img {
    /* height: 75px; */
}

.what-inner h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-weight: 600;
}

.whatbg {
    padding: 30px 0 50px;
}

ul.footermenu li i {
    margin-right: 4px;
}


.what-inner p {
    color: var(--searchbg-color);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.bann {
    margin-bottom: 0;
    flex-direction: column;
    display: flex;
    gap: 10px;
}

.bann img {
    max-height: 167px;
    border-radius: 10px;
    max-width: 500px;
}

.countpic {
    position: relative;
}

.morew p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 0px;
}

.related .tbb {
    padding: 5px 0 15px;
}

.morew p {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    text-shadow: 0px 2px 10px #1e1616;
    text-align: center;
    cursor: pointer;
    transition: all .5s ease-in;
}

.countpic .morew {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    background: #0a0a0ade;
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.bnraedcrunn a:hover {
    color: var(--bluebg-color);
    text-decoration: underline;
}

.bnraedcrunn a {
    text-decoration: none;
    color: var(--searchbg-color);
    font-weight: 500;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: yellow;
    padding: 50px;
    font-size: 20px;
}

.scrollspy-example {
    height: 1500px;
    margin-top: 0.5rem;
    overflow: auto;
}

.submenupage1 {
    overflow-y: auto;
    position: sticky;
    top: 60px;
    z-index: 1;
    margin-top: 0px;
    background: #fff;
}

.middle-cnt {
    margin-top: 63px;
}

.sublinks {
    margin-bottom: 20px;
}

.error-message-login {
    color: #dc3545;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}

.list-group.submenupage1 {
    background-color: var(--whitetext-color);
    padding: 10px;
}

.submenupage .list-group-item+.list-group-item {
    /* border-top-width: inherit; */
}

.Epid ul li p {
    margin-bottom: 2px;
}

main a,
main button,
main input,
main select,
main textarea,
main h2,
main h3,
main h4,
main p [tabIndex="0"] {
    scroll-margin-top: 300px;
    scroll-margin-bottom: 100px;
}

section h3,
section [tabIndex="0"] {
    scroll-margin-top: 300px;
    scroll-margin-bottom: 100px;
    scroll-padding-top: 500px;
}

.bannerslider img {
    border-radius: 10px;
}

.bannerslider {
    padding: 15px 0 0;
    border-radius: 10px;
    margin-bottom: 15px;
}

.bannerslider h5 {
    color: var(--yellowbg-color);
    text-transform: uppercase;
    font-size: 13px;
    text-shadow: 0px 2px 10px #1b2b55;
}

.bannerslider p {
    color: var(--whitetext-color);
    font-size: 25px;
    text-shadow: 0px 2px 10px var(--footerbg-color);
}

.bannerslider .carousel-caption {
    bottom: 30%;
}

.overlayslider {
    position: absolute;
    width: 100%;
    height: 100%;
    /* z-index: 2; */
    background: rgb(43 55 88 / 59%);
    opacity: 1;
    top: 0;
    border-radius: 10px;
}

.bannerslider .carousel-indicators [data-bs-target] {
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-radius: 4px;
    height: 5px;
    width: 15px;
}

.homeheading h2 {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 25px;
}

.client img {
    height: 35px;
}

.story.homeheading h2 {
    margin-bottom: 0px;
}

.client-in {
    display: flex;
    gap: 10px;
    padding: 20px 0 30px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.testimonialsbg {
    background-color: var(--whitetext-color);
    padding: 50px 0;
}

.testimonialpic {
    text-align: right;
}

.testimonialpic img {
    height: 250px;
    border-radius: 10px;
}

.testitext span {
    font-weight: 700;
}

.testitext {
    padding: 50px 0;
}

.testitext h3 {
    margin-bottom: 20px;
}

p.desig {
    color: var(--yellowbg-color) !important;
    font-weight: 600;
}

.outbreak-text p {
    color: var(--heading-color) !important;
    margin-bottom: 0px;
    font-weight: 500;
}

.outbreak-text span {
    color: var(--searchbg-color);
    margin-bottom: 10px;
    display: inline-block;
}

.outbreak-scroll {
    height: 40vh;
    overflow: auto;
    padding-right: 10px;
}

.newsnevents {
    margin: 40px 0;
}

.serr {
    font-size: 26px;
    font-weight: 500;
    color: var(--bluebg-color);
    margin-bottom: 70px;
}

.sericons {
    position: relative;
}

.servicert p {
    font-size: 14px;
}

.servicert {
    text-align: center;
}

.sertext img {
    width: 100%;
}


.peachbg {
    background: var(--peach-color);
    padding: 50px 0;
}

.sertop {
    background: var(--yellowbg-color);
    color: var(--heading-color);
    padding: 10px;
    display: flex;
    max-width: 300px;
    margin: auto;
    gap: 10px;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    top: -5%;
    transform: translate(-50%, -5%);
}

.serrt {
    position: relative;
}

.sertop1 p {
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 0;
}

.sertop1 span {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0;
    line-height: 13px;
}

.sertop1 {
    display: flex;
    gap: 5px;
    align-items: center;
}

p.txtt {
    margin-bottom: 50px;
}

.sericons-inner span img {
    height: 50px;
}

.sericons-in {
    display: flex;
    justify-content: left;
    gap: 80px;
}

.sericons-inner {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 70px;
    position: relative;
}

.sericons-inner p {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 15px !important;
}

.sericons:before {
    content: "";
    position: absolute;
    top: 18px;
    left: 25px;
    height: 234px;
    width: 1px;
    border-right: 1px dashed #d1a95a;
}

.testimonialsbg .carousel-indicators {
    justify-content: left;
    margin-left: 0;
}

.testimonialsbg .carousel-indicators [data-bs-target] {
    background: #ffb41f;
    border-radius: 15%;
}

.custumclient a i {
    font-size: 40px;
    color: #2b3758;
}

.clients {
    padding: 20px 0 50px;
}

span.breadsum {
    color: var(--yellowbg-color);
    font-weight: 700;
    font-style: italic;
}

.detailspagetxt ul {
    list-style: circle;
    padding-left: 35px;
    margin: 10px 0;
}

h4.dosi1 {
    font-size: 13px !important;
    padding: 5px 10px;
    background: #b6bedd;
    border: 1px solid #a0a7c3;
    margin-bottom: 2px;
}

.cv ul li {
    margin-bottom: 5px;
    padding: 5px 10px;
    margin-left: 0px;
    color: #0f2957;
    background: var(--lightbluebg-color);
    border: 1px solid #c6e3eb;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.cv ul {
    padding-left: 0;
}

.foodh {
    font-size: 15px;
    font-weight: 500;
    color: var(--heading-color);
    padding: 2px 10px;
    background: #b6bedd;
    border: 1px solid #a0a7c3;
    margin-bottom: 1px;
}

.foodtbl {
    margin-bottom: 20px;
}

.foodtbl {
    max-height: 200px;
    overflow: auto;
}

.foodtbl table.tbl-d tr th {
    position: sticky;
    top: -1px;
}

.foodtbl table.tbl-d tr td {
    border: 1px solid #ccc;
}

h3.foodtblhead {
    color: var(--bluebg-color);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

.calory {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border-top: 1px solid #ddd;
    line-height: 30px;
    padding: 0 10px;
}

.topnutri {
    border: 1px solid #ddd;
}

.calory:first-child {
    border: 0;
}

.calory span {
    font-size: 14px;
    font-weight: 600;
    color: #0f2957;
}

ul.caloryper {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
}

ul.caloryper li span {
    float: right;
    font-weight: 700;
}

ul.caloryper li {
    padding: 5px 10px;
    color: var(--bluebg-color);
    font-weight: 500;
    font-size: 14px;
}

ul.caloryper {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

li.d1 {
    background: #ffc114 !important;
}

li.d2 {
    background: #ffc1149e !important;
}

li.d3 {
    background: #ffd96d8f !important;
}

.daily-diet {
    font-size: 14px;
    float: left;
    width: 100%;
    text-align: center;
    color: var(--bluebg-color);
    font-style: italic;
    padding: 10px;
    margin-bottom: 20px;
}

.calaoriestxt h5 {
    font-size: 13px;
    color: #de4361;
    font-weight: 600;
    font-family: 'Poppins';
}

.calaoriestxt h4 {
    font-size: 15px;
    margin-bottom: 5px;
    font-family: 'Poppins';
}

.calaoriestxt {
    display: flex;
    gap: 5px;
    flex-direction: column;
    text-align: center;
}

.servingsize {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 10px;
    align-items: center;
    background: #eff6f8;
    padding: 10px;
    border: 1px solid #e4f0f3;
    font-size: 13px;
    flex-wrap: wrap;
}

.serving input[type="text"] {
    max-width: 94px;
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 30px;
    text-align: center;
    font-weight: 600;
}

.serving select {
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 30px;
}

.serving input[type="submit"] {
    background: var(--bluebg-color);
    color: var(--whitetext-color);
    padding: 5px 20px;
    border-radius: 30px;
    border: none;
}

.bluebgsubbanner {
    background: var(--bluebg-color);
    padding: 30px 0 100px;
}

.bluebgsubbanner h3 {
    font-size: 27px;
    font-family: 'Poppins', sans-serif;
}

.bluebgsubbanner p {
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
}

.topbox {
    /* background-color: var(--lightbluebg-color); */
    background: #F2F3F4;
    padding: 25px;
    border-radius: 10px;
    margin-top: -66px;
    position: relative;
    margin-bottom: 20px;
    /* min-height: 200px; */
}

.alld {
    color: var(--heading-color);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 12px;
}

.warningtxt {
    color: #FF8A92;
    background: #FFEFEE;
    padding: 10px;
    font-size: 12px;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}

.interection-ser {
    position: relative;
    margin-bottom: 20px;
}

.interection-ser input {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--border-color) !important;
    background-color: transparent;
    padding: 10px 20px 10px 30px;
}

.bodyparttxt {
    margin-bottom: 10px;
    color: var(--heading-color);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    text-align: left;
}

.alldiesescr {
    max-height: 15vh;
    overflow: auto;
}

img.drugs {
    position: absolute;
    left: 5px;
    top: 11px;
    height: 15px;
    z-index: 1;
}

img.drugsserch {
    position: absolute;
    right: 12px;
    top: 10px;
    height: 14px;
}

.checkik-in {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 5px;
    margin-bottom: 10px;
}

.ins-ch {
    width: fit-content;
}

.checkik {
    background-color: #2b3758;
    color: #fff;
    font-weight: 600;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.checkik-no {
    color: #FF8A92;
    background: #FFEFEE;
    font-weight: 600;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
}

.pagination-cnt button.page-link {
    color: #2e3556;
}

.checkik-found {
    color: #2b3758;
    font-weight: 600;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    background: #EAF6FF;
}


.instraf span img {
    height: 12px;
}

.instraf {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #2b3758;
    border-radius: 10px;
    padding: 15px 15px 5px;
    margin-bottom: 15px;
    border: 1px solid #eee6ba;
}

.instraf-in p {
    margin-bottom: 3px;
}

.instraf-in span {
    margin-bottom: 10px;
    display: inline-block;
    color: #5E707B;
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
}

.instraf-in p strong {
    font-weight: 600;
}

.instrac {
    margin-bottom: 10px;
}

.harmful {
    background: #FEEEED !important;
}

.harmful .instraf span {
    background: #FF0100;
}

.medi-details.bgh {
    border: 1px solid var(--border-color);
    border-radius: 10px 10px 0 0;
}

.medi-details-h {
    padding: 10px;
    background-color: var(--lightbluebg-color);
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.medi-details h5 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0px;
}

.medi-sub1 h4 span {
    color: #5E707B;
}

.medi-br:last-child {
    border-bottom: 0px;
}

.medi-sub1 h4 {
    font-size: 16px;
    margin-bottom: 5px;
}

.medi-pad p {
    margin-bottom: 12px;
}

.clearallbtnre i {
    color: #DE4361;
    cursor: pointer;
}

.medi-pad {
    padding: 10px;
}

.medi-details-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clearallbtn {
    color: #5E707B;
    font-size: 12px;
    cursor: pointer;
}

button.foods {
    background: var(--bluebg-color);
    color: var(--whitetext-color);
    padding: 5px 20px;
    border-radius: 30px;
    border: none;
}

/* .ailments-in .tab-pane.fade {
    opacity: 1;
} */


/* slider css */
#slider-text {
    padding-top: 40px;
    display: block;
}

#slider-text .col-md-6 {
    overflow: hidden;
}

#slider-text h2 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 3px;
    margin: 30px auto;
    padding-left: 40px;
}

#slider-text h2::after {
    border-top: 2px solid #c7c7c7;
    content: "";
    position: absolute;
    bottom: 35px;
    width: 100%;
}

#itemslider h4 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    margin: 10px auto 3px;
}

#itemslider h5 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    font-size: 12px;
    margin: 3px auto 2px;
}

#itemslider h6 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    ;
    font-size: 10px;
    margin: 2px auto 5px;
}

.badge {
    background: #b20c0c;
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 31px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    font-size: 14px;
    border: 2px solid #FFF;
    box-shadow: 0 0 0 1px #b20c0c;
    top: 5px;
    right: 25%;
}

#slider-control img {
    padding-top: 60%;
    margin: 0 auto;
}

@media screen and (max-width: 992px) {
    #slider-control img {
        padding-top: 70px;
        margin: 0 auto;
    }
}

.carousel-showmanymoveone .carousel-control {
    width: 4%;
    background-image: none;
}

.carousel-showmanymoveone .carousel-control.left {
    margin-left: 5px;
}

.carousel-showmanymoveone .carousel-control.right {
    margin-right: 5px;
}

.carousel-showmanymoveone .cloneditem-1,
.carousel-showmanymoveone .cloneditem-2,
.carousel-showmanymoveone .cloneditem-3,
.carousel-showmanymoveone .cloneditem-4,
.carousel-showmanymoveone .cloneditem-5 {
    display: none;
}

@media all and (min-width: 768px) {

    .carousel-showmanymoveone .carousel-inner>.active.left,
    .carousel-showmanymoveone .carousel-inner>.prev {
        left: -50%;
    }

    .carousel-showmanymoveone .carousel-inner>.active.right,
    .carousel-showmanymoveone .carousel-inner>.next {
        left: 50%;
    }

    .carousel-showmanymoveone .carousel-inner>.left,
    .carousel-showmanymoveone .carousel-inner>.prev.right,
    .carousel-showmanymoveone .carousel-inner>.active {
        left: 0;
    }

    .carousel-showmanymoveone .carousel-inner .cloneditem-1 {
        display: block;
    }
}

@media all and (min-width: 768px) and (transform-3d),
all and (min-width: 768px) and (-webkit-transform-3d) {

    .carousel-showmanymoveone .carousel-inner>.item.active.right,
    .carousel-showmanymoveone .carousel-inner>.item.next {
        -webkit-transform: translate3d(50%, 0, 0);
        transform: translate3d(50%, 0, 0);
        left: 0;
    }

    .carousel-showmanymoveone .carousel-inner>.item.active.left,
    .carousel-showmanymoveone .carousel-inner>.item.prev {
        -webkit-transform: translate3d(-50%, 0, 0);
        transform: translate3d(-50%, 0, 0);
        left: 0;
    }

    .carousel-showmanymoveone .carousel-inner>.item.left,
    .carousel-showmanymoveone .carousel-inner>.item.prev.right,
    .carousel-showmanymoveone .carousel-inner>.item.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        left: 0;
    }
}

@media all and (min-width: 992px) {

    .carousel-showmanymoveone .carousel-inner>.active.left,
    .carousel-showmanymoveone .carousel-inner>.prev {
        left: -16.666%;
    }

    .carousel-showmanymoveone .carousel-inner>.active.right,
    .carousel-showmanymoveone .carousel-inner>.next {
        left: 16.666%;
    }

    .carousel-showmanymoveone .carousel-inner>.left,
    .carousel-showmanymoveone .carousel-inner>.prev.right,
    .carousel-showmanymoveone .carousel-inner>.active {
        left: 0;
    }

    .carousel-showmanymoveone .carousel-inner .cloneditem-2,
    .carousel-showmanymoveone .carousel-inner .cloneditem-3,
    .carousel-showmanymoveone .carousel-inner .cloneditem-4,
    .carousel-showmanymoveone .carousel-inner .cloneditem-5,
    .carousel-showmanymoveone .carousel-inner .cloneditem-6 {
        display: block;
    }
}

@media all and (min-width: 992px) and (transform-3d),
all and (min-width: 992px) and (-webkit-transform-3d) {

    .carousel-showmanymoveone .carousel-inner>.item.active.right,
    .carousel-showmanymoveone .carousel-inner>.item.next {
        -webkit-transform: translate3d(16.666%, 0, 0);
        transform: translate3d(16.666%, 0, 0);
        left: 0;
    }

    .carousel-showmanymoveone .carousel-inner>.item.active.left,
    .carousel-showmanymoveone .carousel-inner>.item.prev {
        -webkit-transform: translate3d(-16.666%, 0, 0);
        transform: translate3d(-16.666%, 0, 0);
        left: 0;
    }

    .carousel-showmanymoveone .carousel-inner>.item.left,
    .carousel-showmanymoveone .carousel-inner>.item.prev.right,
    .carousel-showmanymoveone .carousel-inner>.item.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        left: 0;
    }
}

/* slider css */
ul.treat {
    list-style: none;
    padding: 0;
}

.tname {
    text-decoration: underline;
    font-weight: 600;
    margin-bottom: 10px;
}

.detailspagetxt:last-child {
    border-bottom: 0;
}

ul.clinicalf li {
    margin-left: 35px;
}

ul.clinicalf h4 {
    color: #e79a1a;
    font-weight: 500;
    margin-bottom: 5px;
}

li.diagnos {
    cursor: pointer;
    text-transform: uppercase !important;
}

li.diagnos:hover {
    text-decoration: underline;
}

ul.treatmedi li:hover {
    text-decoration: underline;
}

ul.treatmedi li {
    cursor: pointer;
    text-transform: capitalize;
}

/* .disease-images img {
    width: auto;
    height: 300px;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 5px;
} */
.diffd {
    border-top: 1px solid var(--border-color);
    padding-top: 15px;
}

ul.clinicalf {
    padding-left: 0;
    text-transform: capitalize;
}

.clinicalf-in {
    margin-bottom: 15px;
    display: block;
}

span.inv a {
    text-decoration: none;
    color: var(--searchbg-color);
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
}

span.inv {
    float: right;
    margin-top: -19px;
}

button.proceedbtn.submitbtnstep {
    background: #f35626;
    border: 1px solid #f35626;
}
.loaderselect .loading{
    padding: 10px 0  0 !important;
}
img.loderpic {
    height: 75px;
}

/* no data found */
.nodatafound {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #f35626;
    background-image: -webkit-linear-gradient(92deg, #830303, #dc3545);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: hue 10s infinite linear;
    padding: 10px;
}

@-webkit-keyframes hue {
    from {
        -webkit-filter: hue-rotate(0deg);
    }

    to {
        -webkit-filter: hue-rotate(-360deg);
    }
}

/* no data found */

.highchartcnt h4 {
    text-align: center;
    color: var(--heading-color);
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 700;
}

.highchartcnt {
    background: #eff6f8;
    padding: 10px;
    border: 1px solid #e4f0f3;
}

/* chatbox */
.overlaybg {
    height: 500px;
    width: 500px;
    position: absolute;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: none;
    display: none;
}

.chat-popup.show.show1 .chat_submit_box {
    border-radius: 0 0 10px 10px;
}

.chat-popup.show {
    /* position: fixed; */
    top: 0;
    left: 0;
    background: transparent;
    width: 100%;
    height: 100%;
    z-index: 11;
}

.chat-popup.show1 {
    display: none;
}

p.pretext b {
    font-weight: 400 !important;
}

.button-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.chat-popup.show .overlaybg {
    position: fixed;
    display: block;
    bottom: 0;
    top: initial;
    left: initial;
    right: 5px;
    border-radius: 10px 10px 0 0;
    background: #fff;
    z-index: 2;
    height: 670px;
    width: 570px;
    box-shadow: 0px 0px 3px #ddd;
}

.chat-popup.show1 .overlaybg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px 10px;
    background: #fff;
    display: block;
    width: 650px;
    box-shadow: 0px 2px 10px #2e3556;
    max-width: 650px;
}

.chat-popup.show1 {
    position: fixed;
    top: 0;
    left: 0;
    background: #2e3556d4;
    width: 100%;
    height: 100%;
    z-index: 11;
    backdrop-filter: blur(3px);
}

.chat-btn {
    cursor: pointer;
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.chat-popup.show {
    display: block;
}

.chaticnh.showpopup {
    width: 200px;
}

i.chatcls {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    background: #d10f0f;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding: 10px;
    box-shadow: 0px 0px 3px #ddd;
    margin-bottom: 10px;
    align-items: center;
    border-radius: 10px;
}

.chaticnh-in span {
    font-size: 14px;
    font-weight: 700;
    color: #2e3556;
}

.chaticnh-in {
    display: flex;
    gap: 10px;
}

i.expandd {
    cursor: pointer;
    font-size: 15px;
}

.chat10 {
    width: auto;
}

.chat10.showpopup {
    width: 100%;
}

.chat-btn img {
    height: 50px;
}

.serchleft {
    margin-bottom: 61px;
}

.commonheight {
    max-height: 55vh;
}

.Specialty-in.customscroll.commonheight {
    max-height: 58vh;
}

.Specialty-in button.nav-link:hover {
    font-weight: 700;
}

.rt-bn {
    display: inline-block;
    background: #f8bf07;
    padding: 0px 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    margin-bottom: 5px;
    margin-left: 15px;
}

.lightgreen {
    background: #679e42 !important;
    color: var(--whitetext-color) !important;
}

.newlist.nav-pills .nav-link.active,
.newlist.nav-pills .show>.nav-link {
    background: var(--bluebg-color);
    color: var(--whitetext-color);
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
}

.newlist.nav-pills .nav-link {
    border: 1px solid var(--searchbg-color);
    color: var(--searchbg-color);
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
}

ul.newlist {
    display: flex;
    gap: 10px;
    align-items: center;
}

.rop {
    margin-top: 90px;
}

.bluebgg {
    background: #EFF6F8;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.pro-in {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
}

.pro-in h3 {
    font-size: 20px;
    font-weight: 600;
}

.pro p a {
    text-decoration: none;
}

.pro span a {
    color: #515ECB;
    font-weight: 600;
    text-decoration: none;
}

span.viw {
    color: #8a99a0;
}

span.viw i {
    color: #fff;
    width: 15px;
    height: 15px;
    background: #5D707A;
    text-align: center;
    line-height: 15px;
    border-radius: 50%;
    font-size: 8px;
}

.prof {
    gap: 10px;
    display: flex;
    align-items: center;
}

.pro-fill-in {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.chartt {
    margin: 20px 0;
    border: 1px solid #C1C1C2;
    display: flex;
}

.bghh1 {
    border-right: 1px solid #C1C1C2;
    padding: 40px 15px;
    font-size: 14px;
}

.bghh {
    padding: 10px;
    background: #F2F3F4;
    flex-grow: 1;
}

.searchbox-meta input {
    padding: 5px 5px 5px 23px;
    border: none;
    border-bottom: 1px solid #ddd;
    background: transparent;
}

.searchbox-meta i {
    position: absolute;
    left: 6px;
    top: 10px;
    color: #beb9b9;
}

.searchbox-meta {
    position: relative;
}

.totalres p strong {
    color: #6782ee;
    font-weight: 400 !important;
}

.totalres p {
    color: #667277;
}

.printy {
    display: flex;
    gap: 10px;
    align-items: center;
}

.arro i {
    border-radius: 6px;
    background: #BABDBE;
    color: #697276;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}

.res {
    display: flex;
    gap: 10px;
}

.pro-fill2 {
    display: flex;
    gap: 10px;
}

.tertc1 span i {
    background: #5e5e5e;
    color: #fff;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    border-radius: 50%;
    font-size: 9px;
    margin-left: 5px;
}

.tertc {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
}

.tertc-inhead {
    color: #666262;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
}

.haer {
    font-size: 17px;
    margin-bottom: 6px;
    font-weight: 600;
    color: #2e3556;
}

.tertc-summaury p {
    line-height: 20px;
    color: #7a7575;
    text-align: justify;
}

.tertc-summaury p span {
    font-style: italic;
    color: #414b77;
    font-weight: 600;
}

.defaultsummury {
    padding: 0 10px;
    height: 100%;
    position: relative;
}

.tertc-in {
    position: absolute;
    bottom: 180px;
    width: 97%;
}

.tertc1 {
    flex: 1 1;
    background: #eff6f8;
    color: #474e51;
    cursor: pointer;
    padding: 5px 7px;
    border-radius: 10px;
    margin-bottom: 5px;
    font-size: 12px;
}

.tertc1 span {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputtxt1,
.inputtxt2,
.inputtxt3,
.inputtxt4 {
    display: none;
}



.chat_box .chat_message_wrapper ul.chat_message>li+li {
    margin-top: 4px;
}

.popup-box-on {
    display: block !important;
}

a:focus {
    outline: none;
    outline-offset: 0px;
}

.popup-head-left.pull-left h1 {
    color: #fff;
    float: left;
    font-family: oswald;
    font-size: 18px;
    margin: 2px 0 0 5px;

}

.popup-head-left a small {
    display: table;
    font-size: 11px;
    color: #fff;
    line-height: 4px;
    opacity: 0.5;
    padding: 0 0 0 7px;
}

.chat-header-button {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #fff;
    border-radius: 7px;
    font-size: 15px;
    height: 26px;
    opacity: 0.9;
    padding: 0;
    text-align: center;
    width: 26px;
}

.popup-head-right {
    margin: 9px 0 0;
}

.popup-head .btn-group {
    margin: -5px 3px 0 -1px;
}

.gurdeepoushan .dropdown-menu {
    padding: 6px;
}

.gurdeepoushan .dropdown-menu li a span {
    border: 1px solid;
    border-radius: 50px;
    display: list-item;
    font-size: 19px;
    height: 40px;
    line-height: 36px;
    margin: auto;
    text-align: center;
    width: 40px;
}

.gurdeepoushan .dropdown-menu li {
    float: left;
    text-align: center;
    width: 33%;
}

.gurdeepoushan .dropdown-menu li a {
    border-radius: 7px;
    font-family: oswald;
    padding: 3px;
    transition: all 0.3s ease-in-out 0s;
}

.gurdeepoushan .dropdown-menu li a:hover {
    background: #304445 none repeat scroll 0 0 !important;
    color: #fff;
}

.popup-head {
    background: #304445 none repeat scroll 0 0 !important;
    border-bottom: 3px solid #ccc;
    color: #fff;
    display: table;
    width: 100%;
    padding: 8px;
}

.popup-head .md-user-image {
    border: 2px solid #5a7172;
    border-radius: 12px;
    float: left;
    width: 44px;
}

.uk-input-group-addon .glyphicon.glyphicon-send {
    color: #ffffff;
    font-size: 21px;
    line-height: 36px;
    padding: 0 6px;
}

.chat_box_wrapper.chat_box_small.chat_box_active {
    height: 560px;
    overflow-y: scroll;
    max-width: 650px;
    width: 100%;
    padding-bottom: 150px;
}

button.chatt:disabled {
    background: #ddd;
    border: 1px solid #ddd;
}

button.chatt {
    border: none;
    background: transparent;
}

span.uk-input-group-addon {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.chaticnh-in span img {
    height: 23px;
    margin-right: 5px;
}

.chat_box {
    padding: 0 10px;

}

.chat_box .chat_message_wrapper::after {
    clear: both;
}

.chat_box .chat_message_wrapper::after,
.chat_box .chat_message_wrapper::before {
    content: " ";
    display: table;
}

.chat_box .chat_message_wrapper .chat_user_avatar {
    float: left;
}

.chat_box .chat_message_wrapper {
    margin-bottom: 10px;
}

.md-user-image {
    border-radius: 50%;
    width: 25px;
}

p.pretext {
    /* white-space: pre; */
    white-space: pre-line;
}

.relatedmedi {
    margin-bottom: 0;
    padding-bottom: 1px;
    border: 0;
}

.desmed {
    margin-bottom: 15px;
    padding: 10px 15px 10px;
}

.relatedmedi .detailspagetxt {
    padding-bottom: 0;
}

img {
    border: 0 none;
    box-sizing: border-box;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

.chat_box .chat_message_wrapper ul.chat_message,
.chat_box .chat_message_wrapper ul.chat_message>li {
    list-style: outside none none;
    padding: 0;
}

.chat_box .chat_message_wrapper ul.chat_message {
    float: left;
    margin: 0 0 0 20px;
    max-width: 77%;
}

.chat_box.chat_box_colors_a .chat_message_wrapper ul.chat_message>li:first-child::before {
    border-right-color: #616161;
}

.chat_box .chat_message_wrapper ul.chat_message>li:first-child::before {
    border-color: transparent #ededed transparent transparent;
    border-style: solid;
    border-width: 0 16px 16px 0;
    content: "";
    height: 0;
    left: -14px;
    position: absolute;
    top: 0;
    width: 0;
}

.chat_box.chat_box_colors_a .chat_message_wrapper ul.chat_message>li {
    background: #FFF9EA none repeat scroll 0 0;
    color: #000000;
}

.open-btn {
    border: 2px solid #189d0e;
    border-radius: 32px;
    color: #189d0e !important;
    display: inline-block;
    margin: 10px 0 0;
    padding: 9px 16px;
    text-decoration: none !important;
    text-transform: uppercase;
}

.chat_box .chat_message_wrapper ul.chat_message>li {
    background: #ededed none repeat scroll 0 0;
    border-radius: 4px;
    clear: both;
    color: #212121;
    display: block;
    float: left;
    font-size: 13px;
    padding: 8px 16px;
    position: relative;
    word-break: break-all;
}

.chat_box .chat_message_wrapper ul.chat_message,
.chat_box .chat_message_wrapper ul.chat_message>li {
    list-style: outside none none;
    padding: 0;
}

.chat_box .chat_message_wrapper ul.chat_message>li {
    margin: 0;
}

.chat_box .chat_message_wrapper ul.chat_message>li p {
    margin: 0;
    word-break: break-word;
    padding: 8px 16px;
}

.chat_box.chat_box_colors_a .chat_message_wrapper ul.chat_message>li .chat_message_time {
    color: rgba(185, 186, 180, 0.9);
}

.chat_box .chat_message_wrapper ul.chat_message>li .chat_message_time {
    color: #727272;
    display: block;
    font-size: 11px;
    padding-top: 2px;
    text-transform: uppercase;
}

.chat_box .chat_message_wrapper.chat_message_right .chat_user_avatar {
    float: right;
}

.chat_box .chat_message_wrapper.chat_message_right ul.chat_message {
    float: right;
    margin-left: 0 !important;
    margin-right: 24px !important;
}

.chat_box.chat_box_colors_a .chat_message_wrapper.chat_message_right ul.chat_message>li:first-child::before {
    border-left-color: #F0F6FA;
}

.chat_box.chat_box_colors_a .chat_message_wrapper ul.chat_message>li:first-child::before {
    border-right-color: #FFF9EA;
}

.chat_box .chat_message_wrapper.chat_message_right ul.chat_message>li:first-child::before {
    border-color: transparent transparent transparent #ededed;
    border-width: 0 0 29px 29px;
    left: auto;
    right: -14px;
}

.chat_box.chat_box_colors_a .chat_message_wrapper.chat_message_right ul.chat_message>li {
    background: #F0F6FA none repeat scroll 0 0;
}

.chat_box .chat_message_wrapper ul.chat_message>li {
    background: #F0F6FA none repeat scroll 0 0;
    border-radius: 12px;
    clear: both;
    color: #212121;
    display: block;
    float: left;
    font-size: 13px;
    position: relative;
}

.gurdeep-chat-box {
    float: left;
    padding: 0px;

}

.tertc1:focus {
    outline: 1px dashed #d7e6e9;
}

.gurdeep-chat-box input {
    width: 100%;
    box-shadow: 0px 0px 5px #beb9ba;
    padding: 5px;
    height: 40px;
    resize: none;
    background: #F2F3F4;
    border-radius: 5px;
    border: none;
}

.gurdeep-chat-box input:focus {
    outline: none;
}

.gurdeep-chat-box textarea {
    width: 100%;
    border: none;
    padding: 5px 10px;
    height: 90px;
    resize: none;
    background: #F2F3F4;
    border-radius: 5px 0 0 5px;
    border-right: 1px solid #d9d7d7;
}

.gurdeep-chat-box textarea:focus {
    outline: none;
}

select.cooun:focus {
    outline: none;
}

#submit_message {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    padding: 4px;
}

.gurdeep-chat-box i {
    color: #333;
    font-size: 21px;
    line-height: 1px;
}

.chat_submit_box {
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    overflow: hidden;
    padding: 10px;
    position: absolute;
    width: 100%;
    box-shadow: 0px 2px 10px #ddd;
    background: #fff;
}

.uk-input-group {
    border-collapse: separate;
    display: flex;
    position: relative;
    gap: 10px;
    background: #F2F3F4;
    border-radius: 5px;
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
}

button.chatt img {
    height: 30px;
}

.clearchatbtn img {
    height: 30px;
}



/* chatbox */

/* Auto complete searchbox start */
.react-autosuggest__container {
    position: relative;
}


.react-autosuggest__suggestions-container {
    display: none;
    position: absolute;
    top: 40px;
    width: 100%;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    box-shadow: 0px 2px 10px #ddd;
    max-height: 400px;
    overflow: auto;
}

.react-autosuggest__suggestions-container--open {
    display: block;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 10px;
    border-bottom: 1px solid #dddddd47;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #2b3758;
    color: #fff;
}

/* Auto complete searchbox start */

.medi:hover i {
    color: #dc3545;
}

.inv-h {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
}

.rts.investrt h4.head {
    background: #eff6f8;
    padding: 10px;
    border-radius: 5px 5px 0 0;
    margin-bottom: 0px;
}

.rts.investrt {
    border: 1px solid #eef6f8;
    border-radius: 5px;
}

.inbesrt-in {
    padding: 0 5px;
}

.invesrt {
    border-bottom: 1px solid #e5eaeb;
    margin-bottom: 10px;
}

.invesrt:last-child {
    border-bottom: none;
}

ul.defi {
    list-style: none;
    padding: 10px;
    margin: 0 0 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    align-items: center;
    background: #EBEBEB;
    border-radius: 10px;
}

.defe-ser {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;
}

.adb {
    background-color: #fff;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 400;
    padding: 3px 10px;
    cursor: pointer;
    color: #2b3758;
    /* border: 1px solid #D6DCEB; */
    text-transform: capitalize;
}

.adb1 {
    background: #FFC114;
    border: 1px solid #FFC114;
    /* color: #0D1F40; */
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    padding: 3px 10px;
    border-radius: 10px;
}

.notedisease {
    color: #D42E32;
    font-style: italic;
    margin-top: 20px;
}

.def-in p {
    margin-bottom: 0;
}

.def-in {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 5px 10px;
    border-bottom: 1px solid #eff6f8;
}

.headd {
    background: #eff6f8;
    padding: 5px 10px;
    border-radius: 5px 5px 0 0;
    font-size: 15px;
    color: #2B3758;
    font-weight: 600;
    text-transform: uppercase;
}

.Deficiencrychk {
    margin-top: 0px;
    border: 1px solid #eff6f8;
    border-radius: 5px 5px;
}

.deficenery {
    display: flex;
    justify-content: right;
}

.tbkhead {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.medi-in-scroll-side {
    max-height: 167px;
    overflow: auto;
}

.loader10 {
    background-color: red;
    padding: 10px;
    display: block;
}

.load {
    color: red;
}

.loader1 {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Loader css */

.loader {
    width: fit-content;
    font-weight: bold;
    font-family: monospace;
    font-size: 20px;
    color: #0000;
    background: linear-gradient(90deg, #C02942 calc(50% + 0.5ch), #000 0) right/calc(200% + 1ch) 100%;
    -webkit-background-clip: text;
    background-clip: text;
    animation: l7 2s infinite steps(11);
}

@keyframes l7 {
    to {
        background-position: left
    }
}

/* Loader css */

.loginct a img {
    height: 21px;
}

.modal-backdrop.show {
    z-index: 111;
}

.header-cnt .fixed-top {
    z-index: 1 !important;
}

.loginct a {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    background: var(--yellowbg-color);
    padding: 7px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: inline-block;
}

.errorcss {
    margin-top: -10px;
}

.Login_container input {
    width: 100%;
    margin-bottom: 0px;
}

.Login_container label {
    width: 100%;
    margin-bottom: 0px;
    color: #5E707B;
    font-weight: 600;
}

.loginbtn button {
    background: var(--footerbg-color);
    display: inline-block;
    padding: 5px 15px;
    border-radius: 30px;
    text-decoration: none;
    color: var(--whitetext-color);
    font-size: 15px;
    border: none;
}

.modalcustom1 {
    padding: 50px;
    border-radius: 20px;
}

.password-validation-errors ul li {
    margin-bottom: 6px;
}

.password-validation-errors ul {
    margin: 0;
    list-style: circle;
}

.password-validation-errors p {
    margin-bottom: 0px;
    font-weight: 600;
}

.password-validation-errors {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.password-validation-errors ul li i {
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
    text-align: center;
    background: #11bd6d;
    color: #fff;
    font-size: 9px;
    margin-left: 10px;
}

.password-strength-progress-bar {
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 600;
    margin-bottom: 10px;
}

.loggin .inputfield input {
    border-bottom: 1px solid #a6b2ba !important;
    border: none;
    border-radius: 0px;
}

.worldmapSearch {
    position: relative;
    margin-bottom: 10px
}

.worldmapSearch input {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 5px 5px 5px 25px;
}

.worldmapSearch i {
    position: absolute;
    top: 9px;
    left: 7px;
    color: #999;
}

#worldMap {
    border-radius: 8px;
}

.nocountymap {
    color: #dc3545;
    font-weight: 400;
    font-size: 15px;
}

.worldmapSearch-in {
    display: flex;
    /* justify-content: space-between; */
    gap: 10px;
    align-items: center;
}

.diseasenamnemap {
    font-size: 20px;
    font-weight: 600;
    color: #29325e;
    margin-bottom: 8px;
}

.btn-close-custom-wp {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #ffb741;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    z-index: 111;
}

.password-validation-error span {
    color: #dc3545;
    padding-right: 4px;
    padding-top: 3px;
    display: inline-block;
}

.inputfield input {
    padding: 5px 19px 5px 24px;
    width: 100%;
    border-radius: 10px;
    border: 0;
}

.loginbtncnt {
    margin: 40px 0 40px;
}

.forgotinput input {
    padding: 5px;
}

.loginbtncnt button {
    background: var(--yellowbg-color);
    display: inline-block;
    padding: 5px 15px;
    border-radius: 30px;
    text-decoration: none;
    color: var(--whitetext-color);
    font-size: 15px;
    border: none;
    box-shadow: 0px 4px 19px 0px rgba(255, 180, 31, 0.30);
    width: 100%;
}

.loggi {
    font-size: 12px;
}

.clearotp {
    display: flex;
    justify-content: right;
    cursor: pointer;
    margin-top: 10px;
    text-decoration: underline;
    color: #5E707B;
    float: right;
    margin-bottom: 25px;
}

.otpdigit {
    margin: 40px 0;
}

.otpdigit p {
    margin-bottom: 0;
}

.reg p {
    font-weight: 400;
    color: #5E707B;
    font-size: 13px;
    margin-bottom: 0px;
}

.reg p span a {
    color: var(--yellowbg-color);
    font-weight: 600;
    text-decoration: none;
}

.mheader-in1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.mheader-in {
    margin-top: 35px;
}

.inputfield {
    display: flex;
    height: 40px;
    padding: 0px 0px;
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
    align-self: stretch;
    position: relative;
    margin-bottom: 20px;
}

.btn-close-custom {
    position: absolute;
    right: -6px;
    top: -5px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #ffb741;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    z-index: 111;
}

.selectshowreport {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 2px 5px;
    width: 100%;
    max-width: 100%;
}

img.log {
    height: 50px;
}

.remfield-in {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.remfield {
    display: flex;
    gap: 10px;
}

.remfield input[type="checkbox"] {
    width: auto;
}

.signin {
    color: #5E707B;
    font-size: 42px;
    font-weight: 500;
    line-height: 44px;
}

.wel span {
    font-weight: 600;
    color: var(--yellowbg-color);
}

.wel {
    color: #132043;
    font-size: 21px;
    font-weight: 400;
}

.Login_container {
    color: #5E707B;
}

img.loginicn {
    position: absolute;
    top: 6px;
    left: 0px;
}

.mheader-inc label {
    text-transform: initial;
}

.mheader-inc .inputfield {
    margin-bottom: 30px;
    margin-top: 5px;
    gap: 1px;
}

.inputfield .eyee {
    position: absolute;
    top: 11px;
    right: 8px;
    color: #667ab0;
    cursor: pointer;
}

.mheader-inc img.loginicn {
    left: 4px !important;
    top: 10px !important;
}

.mheader-inc .inputfield input {
    padding: 10px 19px 10px 24px !important;
}

.optio {
    display: flex;
    gap: 10px;
    margin: 20px 0 0px;
}

.optio input {
    border-bottom: 1px solid var(--yellowbg-color) !important;
    border: none;
    text-align: center;
    font-size: 23px;
    font-weight: 600;
    color: #2e3556;
}

.otpdigit p span {
    color: var(--yellowbg-color);
    font-weight: 600;
}

.Login_container select.cooun {
    margin-left: 25px;
    border: none;
    position: absolute;
    background: transparent;
    left: 0px;
    top: 3px;
    border-bottom: 0px !important;
}

.inputfield.mobiletct input {
    padding: 0px 19px 10px 90px;
}


.optio input::-webkit-outer-spin-button,
.optio input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.optio input[type=number] {
    -moz-appearance: textfield;
}

.num input::-webkit-outer-spin-button,
.num input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.num input[type=number] {
    -moz-appearance: textfield;
}


.password-strength-bar {
    margin-top: 10px;
    display: flex;
    gap: 10px;
}

.progress-bar {
    height: 10px;
    margin-top: 5px;
    border-radius: 5px;
    width: 100%;
}

.Weak .progress-bar-inner {
    background-color: red;
}


.Medium .progress-bar-inner {
    background-color: #d8ad2d;
}

.Strong .progress-bar-inner {
    background-color: green;
}

.progress-bar-inner {
    height: 100%;
    border-radius: 5px;
}

.pbar {
    flex: 1;
}

.updtaed .inputfield {
    margin-bottom: 5px;
}

.password-validation-error {
    color: #1933ad;
}

.profileupdate {
    flex: 1 1 auto;
}

.updtaed img.loginicn {
    top: 11px;
    left: 5px;
}

.updtaed .inputfield input {
    padding: 5px 10px 5px 10px;
    border-radius: 10px 10px;
    /* border: 1px dashed #ddd;
    border-bottom: 1px dashed #ddd !important; */
}

.updtaed .inputfield select {
    padding: 5px 5px 5px 5px;
    border-radius: 10px 10px;
    /* border: 1px dashed #ddd; */
    border-bottom: 0px dashed #ddd !important;
    text-align: left;
    font-weight: 500;
}

.radiobtbn {
    display: flex;
    gap: 10px;
    align-items: center;
}

/* radio button css */
.rdocnt {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rdocnt input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
    margin-bottom: 0 !important;
    display: none;
}

.profileupdate-in {
    /* display: flex;
    gap: 10px;
    flex-wrap: wrap; */
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.inputfield select {
    border-bottom: 1px solid #bfc4c7 !important;
    border: none;
    padding: 0px 0 5px;
    width: 100%;
    color: #5E707B;
    font-weight: 600;
}

.navpro-in {
    display: flex;
    gap: 10px;
    align-items: center;
}

.inputfield input:disabled {
    padding: 5px 10px 5px 10px;
    border-radius: 10px 10px;
    /* border: 1px dashed #ddd;
    border-bottom: 1px dashed #ddd !important; */
    background: #fff;
    margin-top: 5px;
}

.profileupdate-in label {
    text-transform: inherit;
}

.error-message {
    margin-top: 0.25rem;
    font-size: 11px;
    color: #dc3545;
    font-weight: 500;
    font-style: italic;
}

.error1 {
    margin-top: 0.25rem;
    font-size: 13px;
    color: #dc3545;
    font-weight: 500;
    font-style: italic;
}

.grtdatta .inputfield {
    margin-bottom: 5px;
}

.grtdatta img.loginicn {
    top: 6px !important;
    left: 5px;
}

.profileupdatebtn1 button {
    width: auto;
}

.profileupdatebtn1 {
    display: flex;
    gap: 10px;
    margin: 15px 0 0;
}

.proceedbtn img {
    margin-right: 5px;
}

.closeprodit {
    background: #5d707a !important;
    box-shadow: 0px 4px 19px 0px rgb(52 51 48 / 30%) !important;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 30px;
    text-decoration: none;
    color: var(--whitetext-color);
    text-align: center;
    cursor: pointer;
    font-size: 15px;
}

label.custom-file-upload {
    background: #181E41;
    text-align: center;
    cursor: pointer;
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    font-size: 13px;
    border: 1px solid #fff;
    position: absolute;
    bottom: 9px;
    right: -4px;
}

.profilepicvb1 {
    position: relative;
}

.profilepic img {
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    border: 1px dashed #a9a7a7;
    padding: 3px;
}

.profilep {
    display: flex;
    gap: 10px;
    align-items: center;
}

.agewid {
    width: 100px !important;
}

.profileupdatebutton {
    display: flex;
    justify-content: right;
}

.checkmarkrdo {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dcd9d9;
    border-radius: 50%;
    border: 1px solid #ddd;
}

.rdocnt:hover input~.checkmarkrdo {
    background-color: #ccc;
}

.rdocnt input:checked~.checkmarkrdo {
    background-color: #ffb955;
}

.checkmarkrdo:after {
    content: "";
    position: absolute;
    display: none;
}

.rdocnt input:checked~.checkmarkrdo:after {
    display: block;
}

.rdocnt .checkmarkrdo:after {
    top: 7px;
    left: 7px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
}

/* radio button css */
.pdetails {
    font-size: 17px;
    font-weight: 600;
    color: var(--heading-color);
    text-transform: uppercase;
    margin-bottom: 20px;
}

.symptom-in {
    display: flex;
    gap: 30px;
    align-items: flex-start;
    justify-content: space-between;
}

img.fullbodypic {
    max-width: 250px;
    width: 100%;
}

.patientdetails {
    display: flex;
    row-gap: 20px;
    column-gap: 20px;
    flex-wrap: wrap;
}

.patientdetails-in label {
    color: #222;
    width: 100%;
    margin-bottom: 5px;
    text-transform: capitalize;
    font-weight: 500;
}

.patientdetails-in input {
    border-bottom: 1px solid #DCDDDE !important;
    border: none;
    background: transparent;
    padding: 5px 5px 5px 25px;
    margin-bottom: 10px;
    width: 100%;
}

.patientdetails-in select {
    border-bottom: 1px solid #DCDDDE !important;
    border: none;
    background: transparent;
    margin-bottom: 10px;
    width: 100%;
    color: #666;
}

.sel {
    /* flex-grow: 1; */
}

span.htin {
    position: absolute;
    right: 0px;
    top: 8px;
    border-left: 1px solid #D6DCEB;
    padding-left: 5px;
    color: #7575a1;
    font-size: 11px;
}

.bmival {
    flex: 1 1 auto;
}

.sel.num {
    flex: 1;
}

.mertricrdo {
    display: flex;
    gap: 25px;
}

.bmicnt {
    display: flex;
    gap: 20px;
}

.patientdetails-in {
    position: relative;
    flex-grow: 1;
}

.patientdetails-in .sel {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: self-end;
}

.sel img {
    position: absolute;
    top: 6px;
    left: 0px;
}

.bodymassremark {
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: center;
}

.remark-in {
    flex: 1;
}

.remark-col {
    padding: 5px;
    margin-top: 0px;
}

.marotp {
    min-height: 40px;
}

.metricbtns {
    display: flex;
    align-items: center;
}

.txtremark i {
    margin-right: 3px;
}

.txtremark {
    color: #677883;
    margin-top: 3px;
    font-size: 12px;
}

.remark-in:first-child .remark-col {
    background: #98E1FA;
    border-radius: 5px 0 0 5px;
}

.remark-in:nth-child(2) .remark-col {
    background: #76C045;
}

.remark-in:nth-child(3) .remark-col {
    background: #FFB82E;
}

.remark-in:last-child .remark-col {
    background: #EF4C55;
    border-radius: 0px 5px 5px 0px;
}

span.arrowdown {
    width: 100%;
    display: inline-block;
    font-size: 15px !important;
}

.bodymass {
    margin: 25px 0 50px;
}

.bodm {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
}

.remark-healthy {
    margin-top: 0px;
    color: #76c045;
    font-size: 20px;
    font-weight: 600;
    line-height: 17px;
}

.remark-healthy span {
    color: #677883 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
}

.mart {}

.defaultmenu li a {
    padding: 18px 20px !important;
}

.proceedbtn-in {
    display: flex;
    justify-content: end;
    gap: 40px;
    align-items: center;
    margin-top: 20px;
}

.clearallbtnpro {
    color: #FF8A92;
    font-size: 15px;
    cursor: pointer;
}

.addingopop .ret {
    margin-bottom: 10px;
}

.proceedbtn {
    background: var(--footerbg-color);
    display: inline-block;
    padding: 5px 15px;
    border-radius: 30px;
    text-decoration: none;
    color: var(--whitetext-color);
    text-align: center;
    cursor: pointer;
    font-size: 15px;
}

.brnone select {
    border: none !important;
}

.selin {
    display: flex;
    gap: 10px;
    align-items: center;
}

.radiobtnsymstom {
    display: flex;
    gap: 10px;
    /* align-items: center; */
    margin-top: 10px;
}

.sel.selpic select {
    padding: 0 0 0 19px;
}

.sel.selpic img {
    top: -1px;
}

.radiobtnsymstom label {
    margin-bottom: 0;
}

.bmiv {
    display: flex;
    gap: 10px;
}

.symptom-left {
    flex: 1;
}

.gridsys {
    display: flex;
    gap: 25px;
    margin-bottom: 25px;
}

.gridsys .patientdetails-in {
    flex: 1;
}

.inputfield input:focus {
    outline: 0px;
    border-bottom: 1px solid #ffb955 !important;
    border: none;
    border-radius: 0px;
}

.selin input:focus {
    outline: 0px;
    border-bottom: 1px solid #ffb955 !important;
    border-radius: 0px;
}

.ch-inupdate .mheader-in {
    margin-top: 10px;
}

.genderbtn {
    display: flex;
    gap: 10px;
    align-items: center;
}

.genderbtn label.rdocnt {
    margin-bottom: 0;
}

.proceedbtn a {
    color: #fff;
    text-decoration: none;
}

.step-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 15px;
    background-color: #2e355621;
    color: #2e3556;
    font-size: 0px;
}

.stepcl {
    position: relative;
}

.line {
    width: 180px;
    height: 2px;
    background-color: #2e355621;
    display: inline-block;
}

.progress1 .active {
    background: var(--bluebg-color);
}

.stepcompleted {
    background: var(--bluebg-color);
    color: #fff;
}

img.searchpic {
    position: absolute;
    right: 5px;
    top: 11px;
    height: 15px;
    z-index: 1;
}

.adb1:hover {
    background: #ffc11466;
    color: #d3a114;
}

.progress1 {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    margin-top: 50px;
}

.schecker .alld {
    margin-bottom: 0px;
}

.profilestep {
    padding: 10px 10px 0;
}

.steptwobtn {
    margin-top: 0px;
}

.step-text {
    position: absolute;
    top: -30px;
    left: 0;
    transform: translate(-30px, 0);
}

.clearallbtnpro img {
    margin-right: 5px;
    height: 12px;
}

.sysptoms-rt-btns {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: center;
    margin: 20px 0 80px;
}

.outgoingmedicineser {
    max-height: 30vh;
    overflow: auto;
}

.addreport {
    background: var(--yellowbg-color);
    display: inline-block;
    padding: 5px 15px;
    border-radius: 30px;
    text-decoration: none;
    color: var(--whitetext-color);
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
}

.addreport a {
    text-decoration: none;
    color: var(--whitetext-color);
}

.reprtshr {
    color: #132043;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 30px;
}

.labotab-in {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

img.del {
    height: 25px;
    cursor: pointer;
}

.ret1 {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.ret-in label {
    width: 100%;
    margin-bottom: 2px;
}

.ret-in select {
    border-bottom: 1px solid #E7E7E7 !important;
    border: none;
    color: #666;
    margin-bottom: 10px;
    width: 100%;
}

.ret2 {
    flex: 1;
}

.yelowbtn {
    width: 100%;
}

.ret {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.addreportbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.diag-addinfo-in {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.ingoh {
    color: #5E707B;
    margin-bottom: 5px;
    text-align: left;
    font-weight: 600;
}

.infolist-tbl.restbl tr th {
    background: #fff;
    border: none;
}

.infolist-tbl.restbl table tr td {
    border: none;
    background: #f0f6f8;
}

.infolist-tbl.restbl table tr:nth-child(even) td {
    background: #f0f6f8;
}

.infolist-tbl.restbl table {
    border: none;
}

.infolist-tbl.restbl table tr {
    border-bottom: 1px solid #ddd !important;
    border: none;
}

.infolist-tbl.restbl table tr:last-child {
    border-bottom: none !important;
}

.infolist-in {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.infolist {
    background-color: #fff;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 400;
    padding: 5px 10px;
    color: #2b3758;
}

.distab {
    background: #dbeaef;
    border-radius: 5px 5px 0 0;
    border-bottom: 0;
}

.distab .nav-tabs .nav-link {
    color: #5E707B;
    text-align: center;
}

.distab.nav-tabs .nav-link:focus,
.distab .nav-tabs .nav-link:hover {
    color: #5E707B;
}

.distab .nav.nav-tabs {
    display: flex;
    gap: 10px;
    align-items: center;
}

.distab .nav-link {
    flex: 1;
}

.distab .nav-tabs .nav-item.show .nav-link,
.distab .nav-tabs .nav-link.active {
    border: none;
    border-top: 2px solid #ffb41f;
    border-radius: 5px 5px 0 0;
}

.distabcontent {
    background: #fff;
    border-radius: 0 0 5px 5px;
    padding: 10px;
    max-height: 42vh;
    overflow: auto;
}

.progress-bar.ani {
    margin-top: 0;
    height: auto;
}

.pbarstep-in {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
}

p.barheading {
    margin-bottom: 0;
    color: #5E707B;
    font-size: 12px;
    text-align: left;
    text-transform: capitalize;
}

.pbarstep-in .progress {
    height: 13px;
    font-size: 10px;
}

.viewd {
    color: #5E707B;
    border: 1px solid #ffb41f;
    padding: 3px 10px;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
}

.bodypartsinner ul {
    list-style: none;
    padding: 0;
    margin: 0 0 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    align-items: center;
}

.bodypartsinner ul li .bodyorgan {
    background-color: #fff;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 400;
    padding: 5px 10px;
    cursor: pointer;
    color: #2b3758;
}

.bodypartsinner {
    max-height: 50vh;
    overflow: auto;
    padding-right: 20px;
}

.selected {
    background-color: #ffb955 !important;
    color: #fff !important;
}

.pbarstep-in .progress .ani {
    background-color: #ffb41f;
}

.ret-in.addadi {
    display: flex;
    gap: 60px;
}

.sysptoms-rt-btns1 .addreportbtn {
    gap: 10px;
    margin-top: 20px;
    justify-content: right;
}

.addh {
    color: #ffb741;
    font-size: 16px;
    font-weight: 500;
}

.ret-in input {
    border-bottom: 1px solid #E7E7E7 !important;
    border: none;
    color: #666;
    margin-bottom: 10px;
    width: 100%;
}

.addingopop {
    flex: 1;
}

.attrib {
    font-size: 15px;
    margin-bottom: 2px;
    color: #2e3556;
    font-weight: 500;
    text-transform: capitalize;
}

.attribList-in {
    margin-bottom: 12px;
    display: flex;
    row-gap: 5px;
    column-gap: 8px;
    flex-wrap: wrap;
}

span.checkmark {
    white-space: nowrap;
}

label.chkk {
    text-transform: capitalize;
}

.addattri {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
}

.attibute-cnt {
    max-height: 70vh;
    overflow: auto;
}

.symptom-rt {
    text-align: center;
}

.cls-1 {
    fill: red;
}


/* start checkbox css */
.chkk {
    display: block;
    position: relative;
    padding-left: 0px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 400 !important;
}


.chkk input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


.checkmark {
    /* position: absolute; */
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #7a707029;
    display: inline-table;
    padding: 1px 10px;
    color: #7a7070;
}


.chkk:hover input~.checkmark {
    border: 1px solid #ffb741;
}


.chkk input:checked~.checkmark {
    background-color: #ffb741;
    color: #fff;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


.chkk input:checked~.checkmark:after {
    display: block;
}


.chkk .checkmark:after {
    left: 5px;
    top: 1px;
    width: 8px;
    height: 13px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
}

/* end checkbox css */

/* symptopm checker */
svg.ng-star-inserted {
    /* transform: rotate(360deg); */
    /* float: right; */
}

.class_1:hover {
    opacity: 1;
    cursor: pointer;
}

.class_1 {
    opacity: 0;
    display: inline-block;
    -webkit-transition: opacity 0.4s ease;
    -moz-transition: opacity 0.4s ease;
    -o-transition: opacity 0.4s ease;
    transition: opacity 0.4s ease;
}

.bodyPartBG1 {
    border: 1px solid #e3e7f6;
    border-radius: 10px;
    padding: 10px;
    background: #EAEEFE;
}

.bdyprtt {
    padding-right: 14px;
}

.b-cnt {
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.b-ctt-in {
    border-right: 1px solid #C0CCFB;
    padding-right: 15px;
}

ul.bdyp {
    list-style: none;
    margin: 0;
    padding: 0;
}

img.bodyOrganImage {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    padding: 5px;
    background: #fff;
    box-shadow: 0px 2px 10px #c3cbeb;
    margin-bottom: 2px;
}

.sho {
    flex-grow: 1;
}

.bodyparts {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    overflow-x: hidden;
    max-height: 44vh;
}

ul.bdyp1 {
    right: -25px;
}

ul.bdyp1 {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: -26px;
    top: 20px;
}

ul.bdyp li,
ul.bdyp1 li {
    margin-bottom: 15px;
    min-width: 120px;
    flex: 1;
}

.bodyp {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.showw {
    color: var(--heading-color);
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 16px;
    margin-top: 10px;
    text-align: center;
}

.rnmae {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 15px;
}

ul.bdyp1 li img,
ul.bdyp li img {
    max-width: 25px;
    filter: grayscale(1);
    cursor: pointer;
    margin: auto;
    display: none1;
}

ul.bdyp li:hover img,
ul.bdyp1 li:hover img {
    /* transform: scale(1.2); */
    transition: all .5s ease-in;
    filter: grayscale(0);
}

.restbl.investigationtbl {
    max-height: 40vh;
    overflow: auto;
}

.symptom-rt1 {
    width: 100%;
}

.rpi-in img.bodyOrganImage {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    padding: 5px;
    background: #fff;
    box-shadow: 0px 2px 10px #c3cbeb;
    margin-bottom: 8px;
    filter: grayscale(1);
}

.class_1.active {
    opacity: 1;
}

.enc-in {
    display: flex;
    gap: 10px;
}

.enc {
    display: flex;
    gap: 7px;
}

.showd span {
    background: #2e3556;
    padding: 3px 12px;
    border-radius: 5px;
    margin-top: 4px;
    display: inline-block;
    color: #fff;
    cursor: pointer;
    border: 1px solid #2e3556;
}

.nname {
    font-weight: 600;
    color: var(--sub-heading-color);
    margin-top: 0;
}

ul.dis-2 {
    margin-top: 0px;
}

.adidey {
    background: var(--lightbluebg-color);
    border: 1px solid #c6e3eb;
    padding: 10px;
    margin-top: 12px;
}

.adidey:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: #eff6f8;
    transform: rotate(45deg);
    top: -5px;
    right: 12px;
    border-top: 1px solid #c6e3eb;
    border-left: 1px solid #c6e3eb;
}

.showd {
    text-align: right;
    margin-top: -38px;
}

.loading {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
}


.loader_1 {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
}

.loader_1::before,
.loader_1::after {
    content: "";
    grid-area: 1/1;
    --c: no-repeat radial-gradient(farthest-side, #ffb41f 92%, #0000);
    background:
        var(--c) 50% 0,
        var(--c) 50% 100%,
        var(--c) 100% 50%,
        var(--c) 0 50%;
    background-size: 12px 12px;
    animation: l12 1s infinite;
}

.loader_1::before {
    margin: 4px;
    filter: hue-rotate(204deg);
    background-size: 8px 8px;
    animation-timing-function: linear
}

@keyframes l12 {
    100% {
        transform: rotate(.5turn)
    }
}



/* Responsive css */


@media (max-width: 991px) {
    .symptom-rt {
        margin: auto;
    }

    .container.mbheader {
        background: #2b3758;
        max-width: 100%;
    }

    .sysptoms-rt-btns1 .addreportbtn {
        justify-content: center;
    }

    .ret-in.addadi {
        gap: 15px;
        flex-direction: column;
    }

    .ret-in label {
        text-align: left;
        font-size: 11px;
    }

    .progress1 {
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .line {
        width: 32px;
    }

    .step-circle {
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 11px;
    }

    .step-text {
        display: none;
    }

    .symptom-in {
        flex-direction: column;
        gap: 5px;
    }

    .searchmb {
        margin-bottom: 10px;
    }

    .menu a.nav-link {
        padding: 5px 0 !important;
    }
}

@media (max-width: 767px) {
    .customscroll, .commonheight{
        max-height: 23vh !important;
    }
    .chat_box_wrapper.chat_box_small.chat_box_active {
        height: 550px;
    }

    .chat-popup.show .overlaybg {
        width: 93%;
        height: 660px;
    }

    .tertc {
        align-items: normal;
        flex-direction: column;
    }

    .tertc1 {
        width: 95%;
    }

    .alphabet {
        min-width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 10px;
    }

    .alphabet-in {
        overflow-x: auto;
        padding: 0 5px 10px;
        flex-wrap: nowrap;
    }

    .medi-in-scroll-side {
        margin-bottom: 10px;
    }

    .deficenery {
        justify-content: center;
        margin-top: 10px;
    }

    .search {
        min-width: 100%;
    }

    .b-cnt {
        justify-content: center;
    }

    .b-ctt-in {
        border-right: 0;
        padding-right: 0;
    }

    .attrib {
        text-align: left;
    }

    .reprtshr {
        font-size: 13px;
        margin-bottom: 13px;
        text-align: left;
    }

    .reprtshr {
        font-size: 13px;
    }

    .ft-yellow-left h3 {
        font-size: 13px;
    }

    .distab .nav-link {
        flex: 1 1 auto;
    }

    .distab .nav.nav-tabs {
        gap: 2px;
    }

    .diag-addinfo-in {
        flex-direction: column;
    }

    .profilestep {
        padding: 30px 0px 0;
    }

    .marg {
        margin-bottom: 20px !important;
    }

    .sel.num input[type="number"] {
        width: 100%;
    }

    .patientdetails-in input {
        width: auto;
    }

    .bmicnt {
        flex-direction: column;
    }

    .symptom-left {
        width: 100%;
    }

    .gridsys {
        flex-direction: column;
        text-align: left;
    }

    .proceedbtn-in {
        justify-content: center;
    }

    .remark-col {
        border-radius: 5px !important;
    }

    .marotp {
        min-height: auto;
    }

    .remark-in {
        margin-bottom: 10px;
        width: 200px;
    }

    .bodymassremark {
        flex-direction: column;
    }

    .symptom-in {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .loginbtncnt {
        margin: 30px 0;
    }

    img.log {
        height: 30px;
    }

    .mheader-in {
        margin-top: 10px;
    }

    .remfield-in {
        flex-direction: column;
    }

    .wel {
        font-size: 13px;
    }

    .signin {
        font-size: 30px;
    }

    p.lets {
        font-size: 18px;
        margin-bottom: 0px;
    }

    img.contactpic {
        display: none;
    }

    .contacttxt {
        flex-direction: column;
    }

    img.aboutpic {
        display: none;
    }

    .aboutcnt {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 0;
    }

    .pro-fill-in {
        justify-content: left;
    }

    .pro p {
        margin-bottom: 0;
    }

    .pro-in,
    .prof {
        flex-direction: column;
        gap: 0;
    }

    .age-inner input {
        max-width: 100%;
    }

    .age-inner {
        width: 100%;
    }

    .ageecal {
        flex-direction: column;
    }

    .agee {
        flex-direction: column;
    }

    .nutrient-in a {
        font-size: 12px;
    }

    .nutrient-r {
        width: 100%;
    }

    .nutrienttab-rt {
        flex-direction: column;
    }

    .nutrienttab {
        flex-direction: column;
    }

    .alphabet-in {
        margin-top: 15px;
    }

    .serchleft {
        margin-bottom: 10px;
    }

    .what-in {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }

    .what {
        padding: 10px;
    }

    .serr {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .sericons:before {
        height: 140px;
    }

    .sericons-inner {
        margin-bottom: 20px;
    }

    .sericons-in {
        gap: 5px;
        flex-direction: column;
    }

    .testimonialpic img {
        height: 100px;
    }

    .client-in {
        flex-direction: column;
    }

    .submenupage a {
        padding: 10px 10px;
    }

    .detailspagetxt {
        margin-top: 10px;
    }

    .bann1.bann img {
        height: 100px;
    }

    .bann1 img {
        height: 215px;
    }

    .mainheadn {
        font-size: 24px;
    }

    .spacebetween.tbb {
        flex-direction: row;
    }

    .story h2 {
        font-size: 15px;
    }

    .blog-in {
        grid-template-columns: repeat(2, 1fr);
    }

    .bannertxt p {
        font-size: 12px;
    }

    .bannertxt h3 {
        font-size: 20px;
    }

    .filterr {
        flex-direction: column;
        gap: 10px;
    }

    .spacebetween {
        flex-direction: column;
        text-align: center;
    }

    ul.footermenu {
        gap: 10px;
        justify-content: center;
    }

    ul.socialicons {
        justify-content: center;
    }

    .apps {
        flex-direction: column;
    }

    .df {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 480px) {
    .submenupage ul.nav.nav-tabs {
        flex-direction: column;
    }

    .bnraedcrunn {
        text-align: center;
    }

    .blog-in {
        grid-template-columns: repeat(1, 1fr);
    }
}


@media screen and (min-width: 992px) and (max-width: 1399px) {
    .menu a.nav-link {
        font-size: 12px;
        padding: 20px 4px !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .graybtn {
        gap: 3px;
        flex-direction: column;
    }

    .graybtn1 img {
        min-width: auto;
        height: 22px;
    }
}